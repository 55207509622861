.ReactPasswordStrength {
  position: relative;
  font-family: "Roboto";
  font-size: 0.75rem;
}

.ReactPasswordStrength-input {
  border: none;
  box-sizing: border-box;
  font-size: 18px;
  padding: 14px 0 12px 14px;
  width: calc(85% - 28px);
}

.ReactPasswordStrength-input:not(:focus).is-password-invalid {
  color: #D1462F;
}

.ReactPasswordStrength-input:focus {
  outline: none;
}

.ReactPasswordStrength-strength-desc {
  display: block;
  color: transparent;
  transition: color 250ms ease-in-out;
  padding-top: 1px;
}

.ReactPasswordStrength.is-strength-null .ReactPasswordStrength-strength-desc {
  display: none
}

.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-desc {
  color: #f44336
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-desc {
  color: #f44336
}

.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-desc {
  color: #57B8FF
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-desc {
  color: #57B8FF
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-desc {
  color: #10875C
}

.ReactPasswordStrength-strength-block {
  color: transparent;
  transition: color 250ms ease-in-out;
}

.ReactPasswordStrength.is-strength-null .ReactPasswordStrength-strength-block {
  display: none
}

.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-block {
  display: block
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-block {
  display: block
}

.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-block {
  display: block
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-block {
  display: block
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-block {
  display: block
}

.ReactPasswordStrength-strength-bar {
  box-sizing: border-box;
  height: 2px;
  position: relative;
  top: 1px;
  right: 1px;
  transition: width 300ms ease-out;
  width: 0;
}

.ReactPasswordStrength.is-strength-0 .ReactPasswordStrength-strength-bar {
  background: #D1462F;
  width: 20%;
}

.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-bar {
  background: #D1462F;
  width: 40%;
}

.ReactPasswordStrength.is-strength-2 .ReactPasswordStrength-strength-bar {
  background: #57B8FF;
  width: 60%;
}

.ReactPasswordStrength.is-strength-3 .ReactPasswordStrength-strength-bar {
  background: #57B8FF;
  width: 80%;
}

.ReactPasswordStrength.is-strength-4 .ReactPasswordStrength-strength-bar {
  background: #2FBF71;
  width: calc(100% + 2px);
}